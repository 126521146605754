 <template>
    <v-container class="fill-height">
        <vue-loadmore
            :on-refresh="refresh_mr"
            :finished="false"
            style="width: 100%; direction: ltr"
            :pulling-text="$tr('Refresh')"
            :loosing-text="$tr('Refresh')"
            :refresh-text="`${$tr('Loading')}`"
            :loading-text="`${$tr('Loading')}`"
            :finished-text="$tr('Done')"
            :success-text="$tr('Done')"
        >

		<pull-to-refresh-banner
                            ref="banner"
                        ></pull-to-refresh-banner>
            <v-row class="pa-0" no-gutters>

                <v-card
                    outlined
                    width="100%"
                    color="#ffffff77"
                    class="rounded-lg"
                >
                    <v-card-text
                        class="d-flex align-center justify-space-around"
                    >
                        <v-btn
                            class="rounded-pill"
                            elevation="0"
                            :disabled="this_week"
                            color="primary"
                            @click="
                                this_week = true;
                                refresh_mr(null,false, false);
                            "
                        >
                            {{ $tr("This Week") }}
                        </v-btn>
                        <v-btn
                            class="rounded-pill"
                            elevation="0"
                            :disabled="!this_week"
                            color="primary"
                            @click="
                                this_week = false;
                                refresh_mr(null,false, false);
                            "
                        >
                            {{ $tr("Next Week") }}
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-row>

        <v-row>
            <v-col cols="12" class="px-0">
                <v-container fluid class="px-0">
                    <v-fade-transition group>
                        <v-card class="transparent" elevation="0" :key="'tasks'" v-show="show_tasks">
                            <v-card-text class="px-0">
                                <component
                                    :this_week="this_week"
                                    :is="
                                        mapper[content.status] ||
                                        mapper['no_tasks']
                                    "
                                    @refresh="refresh_mr(null, false, true)"
                                    v-bind="content"
                                ></component>
                            </v-card-text>
                        </v-card>
                        <v-row :key="'skeleton'" v-if="show_skeleton">
                            <v-col cols="12">
                                <v-container class="px-4" fluid>
                                    <v-row class="mb-2" :key="'skeleton-1'">
                                        <v-skeleton-loader
                                            :loading="true"
                                            width="100%"
                                            type="article, actions"
                                        ></v-skeleton-loader>
                                    </v-row>
                                    <v-row class="mb-2" :key="'skeleton-2'">
                                        <v-skeleton-loader
                                            :loading="true"
                                            width="100%"
                                            type="article, actions"
                                        ></v-skeleton-loader>
                                    </v-row>
                                    <v-row class="mb-2" :key="'skeleton-3'">
                                        <v-skeleton-loader
                                            :loading="true"
                                            width="100%"
                                            type="article, actions"
                                        ></v-skeleton-loader>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-fade-transition>
                </v-container>
            </v-col>
        </v-row>
	</vue-loadmore>

    </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
import Api from "@/api/api";
export default {
    data() {
        return {
            this_week: false,
            content: {},
            work_days: [],
            show_banner: false,
            show_skeleton: false,
            show_tasks: false,
            mapper: {
                not_create_day: "not-create-day",
                waiting_response: "waiting-response",
                create: "create-bundle",
                rejected_bundle: "create-bundle",
                accepted: "accepted",
            },
        };
    },
    async created() {
        await this.refresh_mr(null, true, false);
    },
    computed: {
        ...sync("app", ["items"]),
        ...sync("user", {
            user: "user",
            loading_cover: "visualization@loading_cover",
        }),
    },
    methods: {
        async refresh(now = false) {
            let response;
            try {
                this.work_days = (
                    await Api.post("task/fetch/work_days", null, {
                        sinceMins: 30, 
                        now: now, 
                    })
                ).data.work_days;
                response = await Api.post(
                    !this.this_week
                        ? "task/fetch/can_i_create_tasks_next_week"
                        : "task/fetch/can_i_create_tasks_this_week",
                    null,
                    {
                        sinceMins: 30,
                        now: now,
                    }
                );
                this.content = response.data.result;
            } catch (error) {
                console.log("err", error);
            }
        },

        async set_loading() {
            this.show_tasks = false;
            this.show_skeleton = false;
            await this.$sleep(100);
            this.show_skeleton = true;
            await this.$sleep(200);
        },
        async clear_loading() {
            this.show_skeleton = false;
            this.show_tasks = false;
            await this.$sleep(150);
            this.show_tasks = true;
        },
        async refresh_mr(done, show_banner = false, now = true) {
            await this.set_loading();
            await this.refresh(now);
            await this.clear_loading();
            if (show_banner) {
                this.$refs.banner.view_banner();
            }
            done?.();
        },
    },
    components: {
        WorkDayNoTasks: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./workday_no_tasks.vue"
            ),
        NotCreateDay: () =>
            import(
                /* webpackChunkName: "NotCreateDay" */
                "./NotCreateDay.vue"
            ),
        CreateBundle: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./create_bundle.vue"
            ),
        WaitingResponse: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./waiting_response"
            ),
        Accepted: () =>
            import(
                /* webpackChunkName: "accepted" */
                "./accepted"
            ),
        PullToRefreshBanner: () =>
            import("../../Admin/Home/components/pullToRefreshBanner/Index.vue"),
    },
};
</script>

<style></style>
